import React from "react";
import {
  TextInput,
  SimpleForm,
  Create,
  useNotify,
  useRedirect,
  SaveButton,
  Toolbar,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";

const CompanyCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="생성" submitOnEnter={true} icon={<AddIcon />} />
    </Toolbar>
  );

  return (
    <Create {...props} title="회사 정보 추가" onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        {/* <TextInput source="id" label="ID" /> */}
        <TextInput source="name" label="회사 대표" />
        <TextInput source="phone" label="회사 전화번호" resettable />
        <TextInput
          source="licensenumber"
          label="허가번호"
          fullWidth
          resettable
        />
        <TextInput source="address" label="회사 주소" fullWidth resettable />
      </SimpleForm>
    </Create>
  );
};

export default CompanyCreate;
