import React from "react";
import { TextInput, SimpleForm, Edit, SaveButton, Toolbar } from "react-admin";

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="저장" redirect="list" submitOnEnter={true} />
  </Toolbar>
);

const PoliceEdit = (props) => (
  <Edit {...props} title="경찰서 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <TextInput source="title" label="경찰서 이름" />
      {/* <TextInput source="phone" label="경찰서 전화번호" />
      <TextInput source="email" label="경찰서 이메일" /> */}
    </SimpleForm>
  </Edit>
);

export default PoliceEdit;
