import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { useTranslate, MenuItemLink } from "react-admin";

// import SubMenu from "./SubMenu";

import PermContactCalendarRoundedIcon from "@material-ui/icons/PermContactCalendarRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import ApartmentRoundedIcon from "@material-ui/icons/ApartmentRounded";
import MessageRoundedIcon from "@material-ui/icons/MessageRounded";
import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";
import packageJson from "../../package.json";

// import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
// import SettingsIcon from "@material-ui/icons/Settings";
// import LabelIcon from "@material-ui/icons/Label";
// import ReviewIcon from "@material-ui/icons/Comment";
// import OrderIcon from "@material-ui/icons/AttachMoney";
// import InvoiceIcon from "@material-ui/icons/LibraryBooks";

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
    menuPlus: false,
    menuMinus: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div style={{ marginTop: 20 }}>
      {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="유저 관리"
        icon={<VisitorIcon />}
        dense={dense}
      > */}
      <MenuItemLink
        to={`/users`}
        primaryText="유저 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<PeopleAltRoundedIcon />}
      />
      {/* <MenuItemLink
          to={`/creditcards`}
          primaryText="크레딧 카드 리스트"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/vouchers`}
          primaryText="바우처 리스트"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
    
      </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuCatalog")}
        isOpen={state.menuCatalog}
        sidebarIsOpen={open}
        name="보디가드 관리"
        icon={<PermContactCalendarRoundedIcon />}
        dense={dense}
      > */}
      <MenuItemLink
        to={`/guards`}
        primaryText="가드 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        leftIcon={<PermContactCalendarRoundedIcon />}
        dense={dense}
      />
      {/* <MenuItemLink
          to={`/banks`}
          primaryText="은행 리스트"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        /> */}
      {/* </SubMenu> */}
      <MenuItemLink
        to={`/reservations`}
        primaryText="경호 예약 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<VerifiedUserRoundedIcon />}
      />
      {/* <MenuItemLink
        to={`/missions`}
        primaryText="매칭 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<CategoryIcon />}
      /> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        sidebarIsOpen={open}
        name="매칭 내역"
        icon={<InvoiceIcon />}
        dense={dense}
        >
        <MenuItemLink
        to={`/matches`}
        primaryText="매칭 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
        <MenuItemLink
        to={`/requests`}
        primaryText="요청 기록"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
      </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuPlus")}
        isOpen={state.menuPlus}
        sidebarIsOpen={open}
        name="정산 내역"
        icon={<OrderIcon />}
        dense={dense}
        >
        <MenuItemLink
        to={`/banks`}
        primaryText="은행 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
        <MenuItemLink
        to={`/creditcards`}
        primaryText="크레딧 카드 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
      </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuMinus")}
        isOpen={state.menuMinus}
        sidebarIsOpen={open}
        name="쿠폰 관리"
        icon={<CategoryIcon />}
        dense={dense}
        >
        <MenuItemLink
        to={`/coupons`}
        primaryText="쿠폰 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
        <MenuItemLink
        to={`/vouchers`}
        primaryText="바우처 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        />
      </SubMenu> */}
      {/* <MenuItemLink
        to={`/user-memos`}
        primaryText="메모"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<CategoryIcon />}
      /> */}
      {/* <MenuItemLink
        to={``}
        primaryText="공지사항"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<NotificationsActiveRoundedIcon />}
      /> */}
      {/* <MenuItemLink
        to={`/reviews`}
        primaryText="리뷰 리스트"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<MessageRoundedIcon />}
      /> */}
      <MenuItemLink
        to={`/feedbacks`}
        primaryText="가드 리뷰"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<MessageRoundedIcon />}
      />
      <MenuItemLink
        to={`/police`}
        primaryText="경찰서 정보"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<AccountBalanceRoundedIcon />}
      />
      <MenuItemLink
        to={`/companies`}
        primaryText="회사 정보"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<ApartmentRoundedIcon />}
      />
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
