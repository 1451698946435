import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  TopToolbar,
  ShowButton,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import PostPagination from "../PostPagination";

const ListActions = (props) => {
  return <TopToolbar></TopToolbar>;
};

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/feedbacks/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const FeedbackList = (props) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      title="가드 리뷰"
      sort={{ field: "createdAt", order: "DESC" }}
      pagination={<PostPagination />}
      perPage={50}
    >
      <Datagrid rowClick="edit">
        <DateField source="createdAt" label="리뷰 날짜" />
        <ReferenceField source="customerId" reference="users" label="회원">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="guardId" reference="guards" label="평가한 가드">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="content" label="리뷰 내용" />
        <ShowButtons />
      </Datagrid>
    </List>
  );
};

export default FeedbackList;
