import React, { useEffect, useState } from "react";
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  ImageField,
  SingleFieldList,
  ArrayField,
  ChipField,
  FunctionField,
  Datagrid,
  ReferenceManyField,
  ReferenceField,
  DateField,
  EditButton,
  ShowButton,
  TopToolbar,
} from "react-admin";
// import GuardMatchList from "./GuardMatchList";
import { makeStyles } from "@material-ui/core";
import Appearance from "../Reviewes/Appearance";
import Kindness from "../Reviewes/Kindness";
import Satisfaction from "../Reviewes/Satisfaction";
import axios from "axios";

const DocumentField = ({ record }) => {
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (record) {
      const fetchData = async () => {
        const url = `https://dev.api.ldgrd.com/download/signed-url`;
        const token = localStorage.getItem("token");
        const options = { headers: { Authorization: `Bearer ${token}` } };

        const result = record.documents.map((item) =>
          axios.post(
            url,
            {
              key: `documents/${record.id}/${item.fileName}`,
              title: item.fileName,
            },
            options
          )
        );
        await Promise.all(result).then((value) => {
          const files = value.map((f) => f.data);
          console.log(files);
          setFile(files);
        });
      };
      fetchData();
    }
  }, [record]);

  return (
    <ul>
      {file.map((f, index) => (
        <a href={f.url} key={index} target="_blank">
          <li>{f.title}</li>
        </a>
      ))}
    </ul>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton
      basePath={basePath}
      record={data}
      label="수정"
      variant="contained"
    />
  </TopToolbar>
);

const GuardShow = (props) => {
  const classes = useStyles();

  return (
    <Show {...props} title="가드 상세" actions={<PostShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" label="ID" /> */}
        <ImageField source="avatar" title="title" label="프로필" />
        {/* <TextField source="state" label="이용 상태" className={classes.root} /> */}
        <TextField source="name" label="가드 이름" />
        {/* <TextField source="grade" /> */}
        <FunctionField
          source="isMan"
          label="성별"
          render={(record) => {
            return (
              <div>
                {record.isMan === true ? <div>남자</div> : <div>여자</div>}
              </div>
            );
          }}
        />
        <TextField source="age" label="나이" />
        <TextField source="phone" label="전화번호" />
        <NumberField source="reviewScore" label="평점" />
        <FunctionField
          source="status"
          label="가드 승인 여부"
          render={(record) => {
            return (
              <div>
                {record.status === "NORMAL" ? (
                  <div>승인완료</div>
                ) : (
                  <div>미승인</div>
                )}
              </div>
            );
          }}
        />
        {/* <NumberField source="reviewScore" label="평점" />
        <BooleanField source="canWalk" label="도보" />
        <BooleanField source="canSurrogate" label="대리운전" />
        <BooleanField source="canDrive" label="가드차량" />
        <NumberField source="workStartTime" label="시작 시간" />
        <NumberField source="workEndTime" label="종료 시간" /> */}
        {/* <ArrayField source="documents" label="자격증">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ArrayField> */}
        <TabbedShowLayout>
          <Tab label="경호 예약">
            <ReferenceManyField
              label=" "
              target="guardId"
              reference="missions"
              filter={{ guardId: "" }}
            >
              <Datagrid>
                {/* <TextField source="id" label="ID" /> */}
                <ReferenceField
                  source="customerId"
                  reference="users"
                  label="회원"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="fromAddress" label="출발지" />
                <TextField source="toAddress" label="도착지" />
                <DateField source="startDate" label="가드 시작일" />
                {/* <DateField source="endDate" label="가드 종료일" /> */}
                {/* <TextField source="isPaid" label="결제 여부" /> */}
                <FunctionField
                  source="state"
                  label="진행 상황"
                  render={(record) => {
                    return (
                      <div>
                        {record.state === "MATCHED" ? (
                          <div>매칭 중</div>
                        ) : record.state === "GUARDING" ? (
                          <div>가드 중</div>
                        ) : (
                          <div>가드 완료</div>
                        )}
                      </div>
                    );
                  }}
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>

          {/* <Tab label="매칭 내역">
            <GuardMatchList />
          </Tab>
          <Tab label="정산 내역"></Tab> */}
          <Tab label="평가">
            <ReferenceManyField
              label=" "
              target="guardId"
              reference="reviews"
              filter={{ guardId: "" }}
            >
              <Datagrid>
                <ReferenceField
                  source="guardId"
                  reference="guards"
                  label="가드"
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  source="customerId"
                  reference="users"
                  label="평가한 회원"
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="total" label="총 평점" />
                <Appearance source="appearance" label="복장" />
                <Kindness source="kindness" label="친절 / 안전도" />
                <Satisfaction source="satisfaction" label="만족도" />
                <DateField source="createdAt" label="리뷰 날짜" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="서류 정보">
            <ReferenceManyField
              target="id"
              label=" "
              reference="guards"
              // filter={{ guardId: "" }}
            >
              <Datagrid>
                <DocumentField source="documents" label="서류 정보" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>

      {/* <List>
        {Guardes.map((record: Guardes) => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/reviews/${record.id}`}
            alignItems="flex-start"
          >
            <ListItemText
              primary={<StarRatingField record={record} size="small" />}
              secondary={record.comment}
              className={classes.listItemText}
              style={{ paddingRight: 0 }}
            />
          </ListItem>
        ))}
      </List> */}
    </Show>
  );
};

export default GuardShow;
