import UserList from "./UserList";
import UserCreate from "./UserCreate";
import UserShow from "./UserShow";
import UserEdit from "./UserEdit";

export default {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  show: UserShow,
};
