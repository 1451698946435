import React from "react";
import {
  TextInput,
  SimpleForm,
  Create,
  useNotify,
  useRedirect,
  SaveButton,
  Toolbar,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";

const PoliceCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label="생성"
        submitOnEnter={true}
        icon={<AddIcon />}
      ></SaveButton>
    </Toolbar>
  );

  return (
    <Create {...props} title="경찰서 추가" onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <TextInput source="title" label="경찰서 이름" />
        {/* <TextInput source="phone" label="경찰서 전화번호" />
      <TextInput source="email" label="경찰서 이메일" /> */}
      </SimpleForm>
    </Create>
  );
};

export default PoliceCreate;
