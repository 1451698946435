import React, { useRef, useEffect, useState } from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  useNotify,
  useRedirect,
  Toolbar,
  SaveButton,
  // ImageInput,
  // SimpleFormIterator,
  // ImageField,
  // ArrayInput,
  // Labeled,
} from "react-admin";
// import { Field } from "react-final-form";
// import { Input } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const IntroInput = () => (
  <>
    <div>
      <TextInput
        name="intro.career"
        component="input"
        type="text"
        variant="filled"
        // placeholder="latitude"
        label="경력"
      />
    </div>
    <div>
      <TextInput
        name="intro.insurance"
        component="input"
        type="text"
        // placeholder="longitude"
        label="보험"
      />
    </div>
  </>
);

const GuardCreate = (props) => {
  const [state, setState] = useState();
  const canvasRef = useRef(null);

  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label="생성"
        submitOnEnter={true}
        icon={<AddIcon />}
      ></SaveButton>
    </Toolbar>
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // var canvas = document.getElementById("canvas");
    // var ctx = canvas.getContext("2d");
    var cw = canvas.width;
    var ch = canvas.height;
    var maxW = 300;
    var maxH = 300;
    var input = document.getElementById("input");
    var output = document.getElementById("file_output");
    input.addEventListener("change", handleFiles);

    function handleFiles(e) {
      var img = new Image();
      img.onload = function () {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        output.value = canvas.toDataURL("image/jpeg", 0.5);
        setState(output.value);
      };
      img.src = URL.createObjectURL(e.target.files[0]);
      console.log(output);
    }
  }, []);

  return (
    <Create {...props} title="가드 추가" onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceInput
          source="userId"
          reference="users"
          label="가드 이름"
          filter={{ legend: "guard" }}
          perPage={1000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {/* <ImageInput source="avatar" accept="image/*" mulitple={false}>
        <ImageField />
      </ImageInput> */}
        <div>
          <input type="file" id="input" hidden />
          <label
            htmlFor="input"
            style={{
              border: "1px solid #b0b0b0",
              padding: 10,
              boxSizing: "border-box",
              background: "#b0b0b0",
              marginBottom: 5,
              borderRadius: 8,
            }}
          >
            이미지 업로드
          </label>
          <br />
          <br />

          <canvas
            id="canvas1"
            width="64px"
            height="64px"
            style={{ border: "1px solid #000" }}
            ref={canvasRef}
          ></canvas>

          <textarea id="file_output" hidden></textarea>
        </div>
        <TextInput
          initialValue={state}
          style={{ display: "none" }}
          source="avatar"
        />

        <TextInput multiline source="age" placeholder="ex) 20대" label="나이" />
        <TextInput
          multiline
          source="license"
          label="경비원 신임교육 이수증 번호"
        />
        <TextInput multiline source="identification" label="주민등록번호" />
        <TextInput multiline source="description" label="정보" />
        <IntroInput source="intro" />
      </SimpleForm>
    </Create>
  );
};

export default GuardCreate;
