import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { array } from "prop-types";

const apiUrl = "https://dev.api.ldgrd.com";
// const apiUrl = "http://localhost:3000";
const httpClient = fetchUtils.fetchJson;

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const params = {
      email: username,
      password: password,
    };
    const res = httpClient(`${apiUrl}/users/login`, {
      headers: new Headers({
        accept: "application/json",
        "Content-Type": "application/json",
      }),
      method: "POST",
      body: JSON.stringify(params),
    }).then(({ json }) => localStorage.setItem("token", json.jwt));
    return Promise.resolve(res);
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
