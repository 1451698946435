import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  TextInput,
  SelectInput,
  Filter,
  ReferenceInput,
  TopToolbar,
} from "react-admin";
import Appearance from "../Reviewes/Appearance";
import Kindness from "../Reviewes/Kindness";
import Satisfaction from "../Reviewes/Satisfaction";

const ReviewFilter = (props) => (
  <Filter {...props}>
    <TextInput label="검색" source="q" alwaysOn />
    <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ListActions = (props) => {
  return <TopToolbar></TopToolbar>;
};

const ReviewList = (props) => (
  <List filters={<ReviewFilter />} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      {/* <ReferenceField source="reviewId" reference="reviews">
        <TextField source="id" />
      </ReferenceField> */}
      {/* <ReferenceField source="matchId" reference="matches">
        <TextField source="name" />
      </ReferenceField> */}
      <ReferenceField source="guardId" reference="guards" label="가드">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="customerId" reference="users" label="회원">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="total" label="총 평점" />
      <Appearance source="appearance" label="복장" />
      <Kindness source="kindness" label="친절 / 안전도" />
      <Satisfaction source="satisfaction" label="만족도" />
      <DateField source="createdAt" label="리뷰 날짜" />
      <DateField source="createdAt" label="리뷰 날짜" />
    </Datagrid>
  </List>
);

export default ReviewList;
