import React from "react";
import {
  TextField,
  Show,
  Datagrid,
  ReferenceField,
  DateField,
} from "react-admin";

const UsermemoShow = (props) => (
  <Show {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="content" />
      <DateField source="createdAt" />
    </Datagrid>
  </Show>
);

export default UsermemoShow;
