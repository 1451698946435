import ReservationsList from "./ReservationsList";
import ReservationsEdit from "./ReservationsEdit";
import ReservationsShow from "./ReservationsShow";
import ReservationsCreate from "./ReservationsCreate";

export default {
  list: ReservationsList,
  edit: ReservationsEdit,
  show: ReservationsShow,
  create: ReservationsCreate,
};
