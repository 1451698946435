import React from "react";
import { useMutation, useRefresh, useNotify, useRedirect } from "react-admin";
import Button from "@material-ui/core/Button";

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource: "guards/verify",
      payload: { id: record.id, data: { isApproved: true } },
    },
    {
      onSuccess: ({ data }) => {
        refresh();
        notify("가드 승인 처리가 완료되었습니다.");
        redirect("list");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  return (
    <Button
      onClick={approve}
      disabled={loading}
      variant="contained"
      color="primary"
    >
      가드승인
    </Button>
  );
};

export default ApproveButton;
