import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Show,
  TextField,
  ReferenceField,
  SimpleShowLayout,
  DateField,
  useShowController,
  TopToolbar,
} from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SimpleMap from "../SimpleMap";
import Button from "@material-ui/core/Button";
import TablePaginations from "../TablePaginations";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar hidden style={{ height: 0, display: "none" }}></TopToolbar>
);

const MissionsList = (props) => {
  const { record } = useShowController(props);
  const [log, setLog] = useState([]);

  useEffect(() => {
    if (record) {
      console.log(record, "record");
      const fetchData = async () => {
        const url = `https://dev.api.ldgrd.com/locations/guard/${record.requestId}/${record.guardId}`;
        const token = localStorage.getItem("token");
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const result = await axios.get(url, options);
        const list = result.data.reverse();
        setLog(list);
      };
      fetchData();
    }
  }, [record]);

  const Aside = ({ record }) => {
    const classes = useStyles();

    return (
      <Show
        actions={<PostShowActions />}
        {...props}
        style={{ width: 900, marginLeft: "1em" }}
        title=" "
      >
        <SimpleShowLayout>
          <div style={{ marginBottom: "2em" }}>
            <h3>가드 이동 경로</h3>
          </div>
          <TablePaginations log={log} />
        </SimpleShowLayout>
      </Show>
    );
  };

  return (
    <Show {...props} title="경호 가드 배정 상세보기" aside={<Aside />}>
      <SimpleShowLayout>
        {/* <TextInput source="id" /> */}
        <ReferenceField source="guardId" reference="guards" label="보디가드">
          <TextField source="name" />
        </ReferenceField>
        {/* <TextInput source="guardName" />
      <TextInput source="guardPhone" />
      <ReferenceInput source="customerId" reference="users">
      <SelectInput optionText="name" />
    </ReferenceInput> */}
        <DateField source="startDate" label="가드 시작일" showTime />
        <DateField source="endDate" label="가드 종료일" showTime />
        <TextField source="fromAddress" label="출발지" />

        {/* <TextInput source="fromLat" />
      <TextInput source="fromLng" /> */}
        <TextField source="toAddress" label="도착지" />
        {/* <TextInput source="toLat" />
      <TextInput source="toLng" /> */}
        <TextField source="purpose" label="경비 목적 또는 내용" />
        <ReferenceField source="companyId" reference="companies" label="회사">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="policeId"
          reference="police"
          label="경찰서 이름"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="startedAt" label="가드 시작시간" showTime />
        <DateField source="endedAt" label="가드 종료시간" showTime />
        {/* <TextInput source="status" /> */}
      </SimpleShowLayout>
    </Show>
  );
};

export default MissionsList;
