import MissionsList from "./MissionsList";
import MissionsEdit from "./MissionsEdit";
import MissionsCreate from "./MissionsCreate";
import MissionsShow from "./MissionsShow";

export default {
  list: MissionsList,
  edit: MissionsEdit,
  create: MissionsCreate,
  show: MissionsShow,
};
