import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  ShowButton,
  Filter,
  TextInput,
  TopToolbar,
  BulkDeleteButton,
  FunctionField,
  EditButton,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import PostPagination from "../PostPagination";

const ListActions = (props) => {
  return <TopToolbar style={{ display: "none" }}></TopToolbar>;
};

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/reservations/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} label="삭제" />
  </Fragment>
);

const ReservationsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="회원 검색" source="customerName" alwaysOn />
    <TextInput label="연락처 검색" source="phone" alwaysOn />
  </Filter>
);

const ReservationsList = (props) => {
  return (
    <List
      filters={<ReservationsFilter />}
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      title="경호 예약 관리"
      pagination={<PostPagination />}
      perPage={50}
    >
      <Datagrid>
        {/* <TextField source="id" label="ID" /> */}
        <DateField source="createdAt" label="신청일" />
        <ReferenceField source="customerId" reference="users" label="회원">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="guardingType"
          label="경호타입"
          render={(record) => {
            return (
              <div>
                {record.guardingType === "PICKUP" ? "안심 픽업" : "신변보호"}
              </div>
            );
          }}
        />
        {/* <TextField source="guardingType" /> */}
        {/* <ReferenceField source="customerName" reference="users">
        <TextField source="name" />
      </ReferenceField> */}
        <TextField source="phone" label="연락처" />
        {/* <TextField source="fromAddress" />
      <TextField source="fromLat" />
      <TextField source="fromLng" />
      <TextField source="toAddress" />
      <TextField source="toLat" />
      <TextField source="toLng" /> */}
        <DateField source="startDate" label="가드 시작일" />
        <DateField source="endDate" label="가드 종료일" />
        {/* <TextField source="purpose" /> */}
        <FunctionField
          source="price"
          label="결제 금액"
          render={(record) => {
            return (
              <div>
                {record.price === undefined ? (
                  ""
                ) : (
                  <div>
                    {String(record.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </div>
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source="isPaid"
          label="결제 여부"
          render={(record) => {
            return (
              <div>
                {record.isPaid === true ? (
                  <div>결제완료</div>
                ) : (
                  <div>미결제</div>
                )}
              </div>
            );
          }}
        />
        {/* <TextField source="status" /> */}
        <EditButton />
        <ShowButtons />
      </Datagrid>
    </List>
  );
};

export default ReservationsList;
