import UsermemoList from "./User-memoList";
import UsermemoEdit from "./User-memoEdit";
import UsermemoCreate from "./User-memoCreate";
import UsermemoShow from "./User-memoShow";

export default {
  list: UsermemoList,
  edit: UsermemoEdit,
  create: UsermemoCreate,
  show: UsermemoShow,
};
