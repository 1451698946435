import React, { useState, useEffect } from "react";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleForm,
  Create,
  DateTimeInput,
  useRedirect,
  SaveButton,
  Toolbar,
  useNotify,
  useField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { DaumPost } from "../DaumPost";
import { DaemPost } from "../DaemPost";
import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
// const TextCustomInput = ({source. stateValue, onClick}) => {
//   const {input, meta} = useField(source);
//   input.value = stateValue;
//   useEffect(() => {
//     input.onChange(stateValue)
//   }, [stateValue])
//   return <TextInput
//   source="fromAddress"
//   label="출발지"
//   initialValue={input.value}
//   value={input.value}
//   onClick={onClick}
// />
// }

import { useInput, required } from "react-admin";

const MissionsCreate = (props) => {
  const fromAddress = props.location.state?.fromAddress;
  const toAddress = props.location.state?.toAddress;

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [startAddress, setStartAddress] = useState();
  const [endAddress, setEndAddress] = useState();

  const id = props.location.state?.id;
  const userId = props.location.state?.userId;
  const fromLat = props.location.state?.fromLat;
  const fromLng = props.location.state?.fromLng;
  const toLat = props.location.state?.toLat;
  const toLng = props.location.state?.toLng;
  // const purpose = props.location.state?.purpose;

  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("가드가 추가 되었습니다!");
    history.go(-1);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleIsOpen = () => {
    setOpen(true);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label="생성"
        submitOnEnter={true}
        icon={<AddIcon />}
      ></SaveButton>
    </Toolbar>
  );

  // const StartInput = () => {
  //   const { source, ...rest } = props;

  //   return (
  //     <BoundedTextField
  //       name="fromAddress"
  //       source="fromAddress"
  //       component="input"
  //       value={startAddress}
  //       type="text"
  //       placeholder={startAddress}
  //       onClick={handleOpen}
  //       style={{
  //         width: 300,
  //         background: "rgba(0, 0, 0, 0.04)",
  //         border: "none",
  //         height: 48,
  //         boxSizing: "border-box",
  //         padding: 10,
  //         outline: "none",
  //         display: "block",
  //         borderRadius: 8,
  //       }}
  //     />
  //   );
  // };

  // const EndInput = () => {
  //   return (
  //     <Field
  //       name="toAddress"
  //       component="input"
  //       type="text"
  //       value={endAddress}
  //       placeholder={endAddress}
  //       onClick={handleIsOpen}
  //       style={{
  //         width: 300,
  //         background: "rgba(0, 0, 0, 0.04)",
  //         border: "none",
  //         height: 48,
  //         boxSizing: "border-box",
  //         padding: 10,
  //         outline: "none",
  //         display: "block",
  //         marginTop: 20,
  //         borderRadius: 8,
  //         marginBottom: 15,
  //       }}
  //     />
  //   );
  // };

  return (
    <Create {...props} title="경호 가드 배정" onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        {/* <TextInput source="id" /> */}
        <TextInput
          source="reservationId"
          label="경호 예약 관리 ID"
          initialValue={id}
          style={{ display: "none" }}
        />
        <TextInput
          source="customerId"
          label="유저 ID"
          initialValue={userId}
          style={{ display: "none" }}
        />
        <ReferenceInput source="customerId" reference="users" label="회원 이름">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="guardId"
          reference="guards"
          label="가드 이름"
          perPage={1000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {/* <TextInput source="guardName" /> */}
        {/* <TextInput source="guardPhone" /> */}
        <DateTimeInput source="startDate" label="가드 시작일" />
        <DateTimeInput source="endDate" label="가드 종료일" />
        {/* <StartInput source="fromAddress" startAddress={startAddress} />
        <EndInput source="toAddress" endAddress={endAddress} /> */}
        <TextInput
          source="fromAddress"
          label="출발지"
          initialValue={startAddress}
          // value={startAddress}
          onClick={handleOpen}
        />

        <TextInput
          source="toAddress"
          label="도착지"
          initialValue={endAddress}
          // value={endAddress}
          onClick={handleIsOpen}
        />
        <TextInput
          source="fromLat"
          initialValue={fromLat}
          style={{ display: "none" }}
        />
        <TextInput
          source="fromLng"
          initialValue={fromLng}
          style={{ display: "none" }}
        />
        <TextInput
          source="toLat"
          initialValue={toLat}
          style={{ display: "none" }}
        />
        <TextInput
          source="toLng"
          initialValue={toLng}
          style={{ display: "none" }}
        />
        {/* <SelectInput
            label="회사대표"
            source="companyId"
            choices={[{, name: "최규성" }]}
            defaultValue={"최규성"}
          /> */}
        <ReferenceInput
          source="companyId"
          reference="companies"
          label="회사대표"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="policeId"
          reference="police"
          label="경찰서 이름"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput
          source="purpose"
          label="경비의 목적 또는 내용"
          fullWidth
          resettable
          // initialValue={purpose}
        />
        <TextInput source="memo" label="메모" fullWidth resettable />
        {/* <TextInput source="status" /> */}
        {isOpen ? (
          <DaumPost
            setAddress={setStartAddress}
            setIsOpen={setIsOpen}
            handleOpen={handleOpen}
            handleIsOpen={handleIsOpen}
          />
        ) : (
          ""
        )}
        {open ? (
          <DaemPost setEndAddress={setEndAddress} setOpen={setOpen} />
        ) : (
          ""
        )}
      </SimpleForm>
    </Create>
  );
};

export default MissionsCreate;
