import GuardList from "./GuardList";
import GuardCreate from "./GuardCreate";
import GuardShow from "./GuardShow";
import GuardEdit from "./GuardEdit";

export default {
  list: GuardList,
  create: GuardCreate,
  show: GuardShow,
  edit: GuardEdit,
};
