import React, { cloneElement, Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  // EmailField,
  DateField,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  BulkDeleteButton,
  EditButton,
} from "react-admin";

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} label="삭제" />
  </Fragment>
);

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton
        basePath={basePath}
        variant="contained"
        color="primary"
        label="생성"
        style={{ fontSize: 14 }}
      />
    </TopToolbar>
  );
};

const PoliceList = (props) => (
  <List
    {...props}
    title="경찰서 리스트"
    actions={<ListActions />}
    bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid>
      {/* <TextField source="id" /> */}
      <TextField source="title" label="경찰서 이름" />
      {/* <TextField source="phone" label="경찰서 전화번호" />
        <EmailField source="email" label="경찰서 이메일" /> */}
      <DateField source="createdAt" label="생성일" />
      <EditButton />
    </Datagrid>
  </List>
);

export default PoliceList;
