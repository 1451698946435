import React from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  ShowButton,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="저장" redirect="list" submitOnEnter={true} />
  </Toolbar>
);

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton
      basePath={basePath}
      record={data}
      variant="contained"
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  </TopToolbar>
);

const SkipInput = ({ record }) => {
  console.log(record);
  delete record.terms;
  delete record.phoneId;
  delete record.status;
  delete record.createdAt;
  delete record.updatedAt;

  return (
    <>
      <div>
        <TextInput
          name="phoneId"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
          // placeholder="latitude"
        />
      </div>
      <div>
        <TextInput
          name="terms.term"
          component="input"
          type="text"
          style={{ display: "none" }}
          // placeholder="longitude"
        />
      </div>
      <div>
        <TextInput
          name="status"
          component="input"
          type="text"
          style={{ display: "none" }}
          // placeholder="longitude"
        />
      </div>
      <div>
        <TextInput
          name="createdAt"
          component="input"
          type="text"
          style={{ display: "none" }}
          // placeholder="longitude"
        />
      </div>
      <div>
        <TextInput
          name="updatedAt"
          component="input"
          type="text"
          style={{ display: "none" }}
          // placeholder="longitude"
        />
      </div>
    </>
  );
};

const UserEdit = (props) => (
  <Edit {...props} title="유저 수정" actions={<PostEditActions />}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <TextInput source="name" label="이름" resettable />
      <BooleanInput source="isMan" label="성별" />
      <TextInput source="email" label="이메일" resettable />
      <TextInput source="phone" label="전화번호" resettable />
      <TextInput source="password" label="비밀번호" resettable />
      <SkipInput />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
