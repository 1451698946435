import React from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
  TopToolbar,
} from "react-admin";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        submitOnEnter={true}
        redirect={`/users/${props.record.userId}/show`}
      />
    </Toolbar>
  );
};

const PostEditActions = ({ basePath, data }) => <TopToolbar></TopToolbar>;

const UsermemoEdit = (props) => (
  <Edit {...props} actions={<PostEditActions />}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
      {/* <TextInput source="id" /> */}
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" label="유저 이름" />
      </ReferenceInput>
      <TextInput source="content" label="메모" resettable />
      {/* <DateInput source="createdAt" />  */}
    </SimpleForm>
  </Edit>
);

export default UsermemoEdit;
