import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  TextInput,
  ShowButton,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  useListContext,
  BulkDeleteButton,
  FunctionField,
  EditButton,
  NumberField,
} from "react-admin";
import ApproveButton from "./ApproveButton";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import PostPagination from "../PostPagination";

const ListActions = (props) => {
  const { className, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton
        basePath={basePath}
        variant="contained"
        color="primary"
        label="생성"
        style={{ fontSize: 14 }}
      />
    </TopToolbar>
  );
};

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/guards/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} label="삭제" />
  </Fragment>
);

const GuardFilter = (props) => (
  <Filter {...props}>
    <TextInput label="가드 검색" source="name" alwaysOn />
    <TextInput label="연락처 검색" source="phone" alwaysOn />
    {/* <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput> */}
  </Filter>
);

const GuardList = (props) => {
  return (
    <List
      filters={<GuardFilter />}
      {...props}
      title="가드 리스트"
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
      pagination={<PostPagination />}
      perPage={50}
    >
      <Datagrid>
        {/* <TextField source="id" label="ID" /> */}
        <TextField source="name" label="가드 이름" />
        <FunctionField
          source="isMan"
          label="성별"
          render={(record) => {
            return (
              <div>
                {record.isMan === true ? <div>남자</div> : <div>여자</div>}
              </div>
            );
          }}
        />
        <TextField source="phone" label="전화번호" />
        <NumberField source="reviewScore" label="평점" />
        <FunctionField
          source="status"
          label="가드 승인 여부"
          render={(record) => {
            return (
              <div>
                {record.status === "NORMAL" ? (
                  <div>승인완료</div>
                ) : (
                  <div>미승인</div>
                )}
              </div>
            );
          }}
        />
        {/* <NumberField source="reviewScore" label="평점" />
          <BooleanField source="canWalk" label="도보" />
          <BooleanField source="canSurrogate" label="대리운전" />
          <BooleanField source="canDrive" label="가드차량" />
          <NumberField source="workStartTime" label="시작 시간" />
          <NumberField source="workEndTime" label="종료 시간" /> */}
        <ApproveButton />
        <EditButton />
        <ShowButtons />
        {/* <ImageField source="avatar" title="title" label="프로필" />
        <BooleanField source="compatible" />
        <ArrayField source="documents" label="자격증">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="description" label="설명" />
        <TextField source="currentLat" label="위도" />
        <TextField source="currentLng" label="경도" />
        <DateField source="updatedAt" label="날짜" />
        <TextField source="state" />
        <ReferenceField source="userId" reference="users">
          <TextField source="id" />
        </ReferenceField>
        <BooleanField source="isMan" label="성별 여부" />
        <NumberField source="year" label="출생년도" />
        <TextField source="age" label="나이" />
        <TextField source="intro.career" /> */}
      </Datagrid>
    </List>
  );
};

export default GuardList;
