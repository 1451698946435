import React from "react";
import {
  TextField,
  Show,
  SimpleShowLayout,
  EmailField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  EditButton,
  ShowButton,
  DateField,
  ReferenceField,
  useShowController,
  TopToolbar,
  FunctionField,
  DeleteButton,
  NumberField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton
      basePath={basePath}
      record={data}
      label="수정"
      variant="contained"
    />
  </TopToolbar>
);

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/feedbacks/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const UserShow = (props) => {
  const { record } = useShowController(props);

  //Copy and edit the resource of props
  // let copyProps = Object.assign({}, props);
  // copyProps.resource = "matches";

  // const handle = () => {
  //   window.location.href = "#/user-memos/create";
  // };

  const MemoButton = () => {
    return (
      <NavLink
        to={{
          pathname: "/user-memos/create",
          state: {
            id: props.id,
          },
        }}
        style={{
          textDecoration: "none",
          color: "#2F80ED",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "10px 0 " }}
        >
          <RateReviewRoundedIcon style={{ marginRight: 10 }} />
          메모 추가
        </Button>
      </NavLink>
    );
  };

  const guard = record?.legend;

  return (
    <Show {...props} title="유저 상세" actions={<PostShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" label="ID" /> */}
        <TextField source="name" label="이름" />
        <EmailField source="email" label="이메일" />
        <TextField source="phone" label="전화번호" />
        <FunctionField
          source="isMan"
          label="성별"
          render={(record) => {
            return (
              <div>
                {record.isMan === true ? <div>남자</div> : <div>여자</div>}
              </div>
            );
          }}
        />
        <FunctionField
          source="legend"
          label="구분"
          render={(record) => {
            return (
              <div>
                {record.legend === "guard" ? (
                  <div>보디가드</div>
                ) : (
                  <div>회원</div>
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source="status"
          label="유저 상태"
          render={(record) => {
            return (
              <div>
                {record.status === "SUBMITTED" ? (
                  <div>가드서류제출완료</div>
                ) : record.status === "VERIFIED" ? (
                  <div>가드승인완료</div>
                ) : record.status === "SIGNINGUP" ? (
                  <div>회원가입완료</div>
                ) : record.status === "MATCHED" ? (
                  <div>매칭완료</div>
                ) : record.status === "GUARDING" ? (
                  <div>가드시작중</div>
                ) : record.status === "NOT_REVIEWED" ? (
                  <div>가드완료 리뷰대기중</div>
                ) : (
                  <div>대기중</div>
                )}
              </div>
            );
          }}
        />
        <ReferenceManyField label="" target="userId" reference="user-memos">
          <Datagrid>
            <TextField source="content" label="메모" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <MemoButton />
        {guard === "guard" ? (
          ""
        ) : (
          <TabbedShowLayout>
            {/* <Tab label="매칭 내역">
            <ReferenceManyField
              label="매칭리스트"
              reference="matches"
              target="customerId"
              source="id"
            >
              <List {...props} filter={{ guardId: props.id }}>
                <Datagrid>
                  <TextField source="id" label="ID" />
                  <TextField source="customerName" label="회원" />
                  <TextField source="fromAddress" label="출발지" />
                  <TextField source="toAddress" label="도착지" />
                  <TextField source="method" label="가드 타입" />
                  <NumberField source="estimatedDistance" label="이동 거리" />
                  <NumberField source="price" label="금액" />
                  <NumberField source="discountPrice" label="할인 금액" />
                  <NumberField source="finalPrice" label="정산" />
                  <TextField source="state" label="상태" />
                  <ReferenceField source="guardId" reference="guards">
                    <TextField source="id" />
                  </ReferenceField>
                  <TextField source="guardName" />
                  <EditButton />
                </Datagrid>
              </List>
            </ReferenceManyField>
          </Tab>
          <Tab label="쿠폰" path="body"></Tab>
          <Tab label="평가" path="miscellaneous"></Tab> */}
            <Tab label="경호예약">
              <ReferenceManyField
                label=""
                target="customerId"
                reference="reservations"
                filter={{ customerId: "" }}
              >
                <Datagrid>
                  {/* <TextField source="id" label="ID" /> */}
                  <FunctionField
                    source="guardingType"
                    label="가드타입"
                    render={(record) => {
                      return (
                        <div>
                          {record.guardingType === "PICKUP" ? (
                            <div>안심 픽업</div>
                          ) : (
                            <div>신변 보호</div>
                          )}
                        </div>
                      );
                    }}
                  />
                  {/* <ReferenceField
                    source="guardId"
                    reference="guards"
                    label="보디가드"
                  >
                    <TextField source="name" />
                  </ReferenceField> */}
                  <TextField source="fromAddress" label="출발지" />
                  <TextField source="toAddress" label="도착지" />
                  <DateField source="startDate" label="가드 시작일" />
                  <DateField source="endDate" label="가드 종료일" />
                  <FunctionField
                    source="isPaid"
                    label="결제 여부"
                    render={(record) => {
                      return (
                        <div>
                          {record.isPaid === true ? (
                            <div>결제완료</div>
                          ) : (
                            <div>미결제</div>
                          )}
                        </div>
                      );
                    }}
                  />
                  <DateField source="createdAt" label="신청일 " />
                  <TextField source="purpose" label="경호 목적" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="리뷰">
              <ReferenceManyField
                label=""
                target="customerId"
                reference="feedbacks"
                filter={{ customerId: "" }}
              >
                <Datagrid>
                  <ReferenceField
                    source="customerId"
                    reference="users"
                    label="회원"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField
                    source="guardId"
                    reference="guards"
                    label="평가한 가드"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="content" label="리뷰 내용" />
                  <DateField source="createdAt" label="리뷰 날짜" />
                  <ShowButtons />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
