import React from "react";
import DaumPostcode from "react-daum-postcode";

export const DaemPost = ({ setEndAddress, setOpen }) => {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setEndAddress(fullAddress);
    setOpen(false);
  };
  return (
    <div
      style={{
        width: 330,
        height: "450px",
        position: "absolute",
        zIndex: 1000,
        top: "50%",
        left: "50%",
        transform: "translateY(-50%)",
        borderRadius: 10,
        background: "#3f51b5",
      }}
    >
      <DaumPostcode
        onComplete={handleComplete}
        style={{
          width: 300,
          margin: "0 auto",
          marginTop: 20,
          height: 480,
        }}
      />
    </div>
  );
};
