import React from "react";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  Edit,
  SimpleForm,
  BooleanInput,
  NumberInput,
  DateInput,
  SaveButton,
  Toolbar,
  TopToolbar,
  ShowButton,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        redirect={`/reservations/${props.record.id}/show`}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton
      basePath={basePath}
      record={data}
      variant="contained"
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  </TopToolbar>
);

const HandleInput = ({ record }) => {
  delete record.guardId;
  delete record.status;

  return (
    <>
      <div>
        <TextInput
          name="guardId"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="status"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
    </>
  );
};

const ReservationsEdit = (props) => (
  <Edit {...props} title="경호 예약 관리 수정" actions={<PostEditActions />}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <TextInput source="guardingType" label="가드타입" resettable />
      <ReferenceInput
        source="customerId"
        reference="users"
        label="회원 이름"
        resettable
      >
        <SelectInput source="name" />
      </ReferenceInput>
      {/* <ReferenceInput source="customerName" reference="users">
        <SelectInput source="name" />
      </ReferenceInput> */}
      <TextInput source="phone" label="연락처" resettable />
      <TextInput source="fromAddress" label="출발지" resettable />
      {/* <TextInput source="fromLat" />
      <TextInput source="fromLng" /> */}
      <TextInput source="toAddress" label="도착지" resettable />
      {/* <TextInput source="toLat" />
      <TextInput source="toLng" /> */}
      <DateInput source="startDate" label="가드 시작일" />
      <DateInput source="endDate" label="가드 종료일" />
      <NumberInput source="price" label="결제 금액" resettable />
      <BooleanInput source="isPaid" label="결제 여부" />
      <TextInput source="purpose" fullWidth resettable label="경호 상세 내용" />
      <HandleInput />
      {/* <DateInput source="createdAt" /> */}
      {/* <TextInput source="status" /> */}
    </SimpleForm>
  </Edit>
);

export default ReservationsEdit;
