import React from "react";
import { SimpleForm, TextInput, SelectInput, Create } from "react-admin";

export const FeedBackCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput optionText="name" />
      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Create>
);

export default FeedBackCreate;
