import React from "react";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleForm,
  Edit,
  DateTimeInput,
  SaveButton,
  Toolbar,
} from "react-admin";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        submitOnEnter={true}
        redirect={`/reservations/${props.record.reservationId}/show`}
      />
    </Toolbar>
  );
};

const MissionsEdit = (props) => (
  <Edit {...props} title="경호 가드 배정 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      {/* <TextInput source="id" /> */}
      <ReferenceInput source="guardId" reference="guards" label="보디가드">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="companyId" reference="companies" label="회사">
        <SelectInput optionText="name" />
      </ReferenceInput>
      {/* <TextInput source="guardName" />
      <TextInput source="guardPhone" />
      <ReferenceInput source="customerId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput> */}
      <DateTimeInput source="startDate" label="가드 시작일" />
      <DateTimeInput source="endDate" label="가드 종료일" />

      <TextInput source="fromAddress" label="출발지" />
      {/* <TextInput source="fromLat" />
      <TextInput source="fromLng" /> */}
      <TextInput source="toAddress" label="도착지" />
      {/* <TextInput source="toLat" />
      <TextInput source="toLng" /> */}
      <TextInput source="purpose" label="보디가드" />
      <ReferenceInput source="policeId" reference="police" label="경찰서 이름">
        <SelectInput optionText="title" />
      </ReferenceInput>
      {/* <TextInput source="status" /> */}
    </SimpleForm>
  </Edit>
);

export default MissionsEdit;
