import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  Filter,
  TextInput,
  ShowButton,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  BulkDeleteButton,
  FunctionField,
  EditButton,
  CardActions,
  ExportButton,
  RefreshButton,
} from "react-admin";
import Button from "@material-ui/core/Button";
import axios from "axios";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import PostPagination from "../PostPagination";

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} label="삭제" />
  </Fragment>
);

// const ListActions = (props) => {
//   const { className, ...rest } = props;
//   const { basePath } = useListContext();
//   return (
//     <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
//       <CreateButton
//         basePath={basePath}
//         variant="contained"
//         color="primary"
//         label="생성"
//         style={{ fontSize: 14 }}
//       />
//     </TopToolbar>
//   );
// };
const ListActions = (
  {
    basePath,
    currentSort,
    exporter,
    filters,
    filterValues,
    resource,
    selectedIds,
    total,
  },
  props
) => (
  <TopToolbar>
    <CreateButton
      basePath={basePath}
      variant="contained"
      color="primary"
      label="생성"
      style={{ fontSize: 14 }}
    />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      color="primary"
      variant="contained"
      style={{ fontSize: 14, marginLeft: 10 }}
    />
  </TopToolbar>
);

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/users/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="유저 검색" source="name" alwaysOn />
    <TextInput label="연락처 검색" source="phone" alwaysOn />
  </Filter>
);

const ResetButton = ({ record }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ width: 100 }}
      onClick={() => Reset(record.id)}
    >
      리셋
    </Button>
  );
};

const Reset = async (id) => {
  const url = `https://dev.api.ldgrd.com/users/${id}/reset`;
  const token = localStorage.getItem("token");
  const options = { headers: { Authorization: `Bearer ${token}` } };

  try {
    await axios.get(url, options);
    window.location.reload();
  } catch (error) {
    alert("다시 시도해 주세요.");
  }
};

const UserList = (props) => {
  return (
    <List
      filters={<UserFilter />}
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      title="유저 관리"
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
      pagination={<PostPagination />}
      perPage={50}
    >
      <Datagrid optimized>
        {/* <TextField source="id" label="ID" /> */}
        <TextField source="name" label="이름" />
        <FunctionField
          source="legend"
          label="구분"
          render={(record) => {
            return (
              <div>
                {record.legend === "guard" ? (
                  <div>보디가드</div>
                ) : (
                  <div>회원</div>
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source="isMan"
          label="성별"
          render={(record) => {
            return (
              <div>
                {record.isMan === true ? <div>남자</div> : <div>여자</div>}
              </div>
            );
          }}
        />
        <EmailField source="email" label="이메일" />
        <TextField source="phone" label="전화번호" />
        <FunctionField
          source="status"
          label="유저 상태"
          render={(record) => {
            return (
              <div>
                {record.status === "SUBMITTED" ? (
                  <div>가드서류제출완료</div>
                ) : record.status === "VERIFIED" ? (
                  <div>가드승인완료</div>
                ) : record.status === "SIGNINGUP" ? (
                  <div>회원가입완료</div>
                ) : record.status === "MATCHED" ? (
                  <div>매칭완료</div>
                ) : record.status === "GUARDING" ? (
                  <div>가드시작중</div>
                ) : record.status === "NOT_REVIEWED" ? (
                  <div>가드완료 리뷰대기중</div>
                ) : (
                  <div>대기중</div>
                )}
              </div>
            );
          }}
        />
        <ResetButton />
        <EditButton />
        <ShowButtons />
        {/* <TextField source="legend" />
      <TextField source="avatar" />
      <ReferenceField source="phoneId" reference="phones">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="status" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="roles" />
      <BooleanField source="terms.term" /> */}
      </Datagrid>
    </List>
  );
};

export default UserList;
