import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
} from "react-admin";

const PaymentsList = (props) => {
  return (
    <List {...props} title="결제 내역">
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="paymentId" reference="payments">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="reservationId" reference="reservations">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="회원 이름">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="price" label="결제 금액" />
        <DateField source="createdAt" label="결제 날짜" />
      </Datagrid>
    </List>
  );
};

export default PaymentsList;
