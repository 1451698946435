import React from "react";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleForm,
  Create,
  BooleanInput,
  NumberInput,
  DateInput,
} from "react-admin";

const ReservationsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="guardingType" />
      <ReferenceInput source="customerId" reference="users">
        <SelectInput source="id" />
      </ReferenceInput>
      {/* <ReferenceInput source="customerName" reference="users">
        <SelectInput source="name" />
      </ReferenceInput> */}
      <NumberInput source="phone" />
      <TextInput source="fromAddress" />
      <TextInput source="fromLat" />
      <TextInput source="fromLng" />
      <TextInput source="toAddress" />
      <TextInput source="toLat" />
      <TextInput source="toLng" />
      <DateInput source="startDate" />
      <DateInput source="endDate" />
      <TextInput source="purpose" />
      <TextInput source="price" />
      <BooleanInput source="isPaid" />
      <DateInput source="createdAt" />
      <TextInput source="status" />
    </SimpleForm>
  </Create>
);

export default ReservationsCreate;
