import React, { useRef, useEffect, useState } from "react";
import {
  // ReferenceInput,
  TextInput,
  // SelectInput,
  Edit,
  SimpleForm,
  BooleanInput,
  // ArrayInput,
  // SimpleFormIterator,
  // NumberInput,
  // DateInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  ShowButton,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        redirect={`/guards/${props.record.id}/show`}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton
      basePath={basePath}
      record={data}
      variant="contained"
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  </TopToolbar>
);

const IntroInput = ({ record }) => {
  if (!record.intro) {
    record.intro = {};
  }
  if (!record.intro.career) {
    record.intro.career = "";
  }
  if (!record.intro.insurance) {
    record.intro.insurance = "";
  }
  return (
    <>
      <div>
        <TextInput
          name="intro.career"
          component="input"
          type="text"
          variant="filled"
          fullWidth
          resettable
          // placeholder="latitude"
          label="경력"
        />
      </div>
      <div>
        <TextInput
          name="intro.insurance"
          component="input"
          type="text"
          // placeholder="longitude"
          fullWidth
          resettable
          label="보험"
        />
      </div>
    </>
  );
};

const LngInput = ({ record }) => {
  delete record.currentLat;
  delete record.currentLng;
  delete record.description;

  if (!record.license) {
    record.license = "";
  }
  if (!record.identification) {
    record.identification = "";
  }

  return (
    <>
      <div>
        <TextInput source="identification" label="주민등록번호" resettable />
      </div>
      <div>
        <TextInput
          multiline
          source="license"
          resettable
          fullWidth
          label="경비원 신임교육 이수증 번호"
        />
      </div>
      <div>
        <TextInput
          name="currentLat"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
          // placeholder="latitude"
        />
      </div>
      <div>
        <TextInput
          name="currentLng"
          component="input"
          type="text"
          style={{ display: "none" }}
          // placeholder="longitude"
        />
      </div>
    </>
  );
};

const GuardEdit = (props) => {
  const [state, setState] = useState();
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // var canvas = document.getElementById("canvas");
    // var ctx = canvas.getContext("2d");
    var cw = canvas.width;
    var ch = canvas.height;
    var maxW = 64;
    var maxH = 64;
    var input = document.getElementById("input");
    var output = document.getElementById("file_output");
    input.addEventListener("change", handleFiles);

    function handleFiles(e) {
      var img = new Image();
      img.onload = function () {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        output.value = canvas.toDataURL("image/jpeg", 0.5);
        setState(output.value);
      };
      img.src = URL.createObjectURL(e.target.files[0]);
      console.log(output);
    }
  }, []);

  return (
    <Edit title="가드 수정" {...props} actions={<PostEditActions />}>
      <SimpleForm sanitizeEmptyValues={false} toolbar={<PostCreateToolbar />}>
        {/* <ReferenceInput source="userId" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput> */}
        {/* <TextInput source="grade" resettable /> */}
        <TextInput source="name" resettable label="가드 이름" />
        <div>
          <input type="file" id="input" hidden />
          <label
            htmlFor="input"
            style={{
              border: "1px solid #b0b0b0",
              padding: 10,
              boxSizing: "border-box",
              background: "#b0b0b0",
              marginBottom: 5,
              borderRadius: 8,
            }}
          >
            이미지 업로드
          </label>
          <br />
          <br />
          <canvas
            id="canvas1"
            width="64px"
            height="64px"
            style={{ border: "1px solid #000" }}
            ref={canvasRef}
          ></canvas>

          <textarea id="file_output" hidden></textarea>
        </div>
        <TextInput
          initialValue={state}
          // style={{ display: "none" }}
          source="avatar"
          resettable
          label="프로필"
        />
        <TextInput source="phone" resettable label="전화번호" />
        <LngInput />
        <IntroInput source="intro" />
        <BooleanInput source="isMan" label="남자면 활성화 상태" />
        {/* <TextInput source="description" resettable label="가드 정보" /> */}
        {/* <TextInput source="status" resettable label="가드 상태" /> */}
        {/* <TextInput source="state" resettable /> */}
        <ArrayInput
          source="documents"
          style={{ display: "none" }}
          display="none"
        >
          <SimpleFormIterator>
            <TextInput source="title" fullWidth resettable />
            <TextInput source="fileName" fullWidth resettable />
            <TextInput
              source="fileUrl"
              initialValue="fileUrl"
              fullWidth
              resettable
            />
          </SimpleFormIterator>
        </ArrayInput>
        {/* <NumberInput source="workStartTime" />
        <NumberInput source="workEndTime" /> */}
        {/* <TextInput source="currentLat" style={{ display: "none" }} disabled /> */}
        {/* <TextInput source="currentLng" style={{ display: "none" }} disabled /> */}
        {/* <DateInput source="updatedAt" /> */}
        {/* <NumberInput source="year" />
        <TextInput source="age" resettable />
        <NumberInput source="reviewScore" /> */}
      </SimpleForm>
    </Edit>
  );
};

export default GuardEdit;
