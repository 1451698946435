import React from "react";
import { Admin, Resource, ListGuesser } from "react-admin";

import authProvider from "./authProvider";
import lb4Provider from "./lb4Provider";

// import { LocationList, LocationCreate } from "./components/location/Locations";

import { Layout } from "./Layout";

import Guard from "./components/Guardes";
import User from "./components/User";
import Reservations from "./components/Reservations";
import Missions from "./components/Missions";
import Usermemo from "./components/UserMemo";
import Companies from "./components/Companies";
import Police from "./components/Police";
import MyLoginPage from "./Layout/MyLoginPage";
import Review from "./components/Reviewes";
import Payment from "./components/Payments/index";

import koreanMessages from "@spectrum/ra-language-korean";
import polyglotI18nProvider from "ra-i18n-polyglot";

// import Match from "./components/Matches";
// import Request from "./components/Request";
// import Bank from "./components/Bank";
// import Coupon from "./components/Coupons";
// import Creditcard from "./components/Creditcard";
import FeedBack from "./components/FeedBack";

const messages = {
  ko: koreanMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages["ko"]);

const App = () => (
  <Admin
    // locale="ko"
    i18nProvider={i18nProvider}
    layout={Layout}
    loginPage={MyLoginPage}
    dataProvider={lb4Provider(
      "https://dev.api.ldgrd.com",
      // "http://localhost:3000",
      () => {
        return {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
      "id"
    )}
    authProvider={authProvider}
  >
    <Resource name="users" {...User} options={{ label: "유저 리스트" }} />
    <Resource name="guards" {...Guard} options={{ label: "가드 리스트" }} />
    <Resource
      name="reservations"
      {...Reservations}
      options={{ label: "경호 예약 관리" }}
    />
    <Resource
      name="missions"
      {...Missions}
      options={{ label: "경호 예약 가드 리스트" }}
    />
    <Resource name="reservation-memos" list={ListGuesser} />
    <Resource
      name="user-memos"
      {...Usermemo}
      options={{ label: "유저 메모" }}
    />
    <Resource name="companies" {...Companies} options={{ label: "회사정보" }} />
    <Resource name="police" {...Police} options={{ label: "경찰서" }} />
    <Resource name="reviews" {...Review} options={{ label: "리뷰 리스트" }} />
    <Resource
      name="feedbacks"
      {...FeedBack}
      options={{ label: "피드백 리스트" }}
    />
    <Resource name="payments" {...Payment} />

    {/* <Resource name="coupons" {...Coupon} options={{ label: "쿠폰 리스트" }} /> */}
    {/* <Resource name="vouchers" options={{ label: "바우처 리스트" }} /> */}
    {/* <Resource
      name="creditcards"
      {...Creditcard}
      options={{ label: "크레딧카드 리스트" }}
    /> */}
    {/* <Resource name="banks" {...Bank} options={{ label: "은행 리스트" }} /> */}
    {/* <Resource name="requests" {...Request} options={{ label: "요청 리스트" }} /> */}
    {/* <Resource name="matches" {...Match} options={{ label: "매치 리스트" }} /> */}
    {/* <Resource name="locations" list={LocationList} create={LocationCreate} /> */}
    {/* <Resource name="phones" list={ListGuesser} /> */}
  </Admin>
);

export default App;
