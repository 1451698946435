import React from "react";
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  TopToolbar,
  SimpleShowLayout,
} from "react-admin";

const ListActions = (props) => {
  return <TopToolbar></TopToolbar>;
};

const FeedbackShow = (props) => (
  <Show {...props} actions={<ListActions />} title="가드 리뷰 상세">
    <SimpleShowLayout>
      {/* <TextField source="feedbackId" label="ID" /> */}
      <ReferenceField source="customerId" reference="users" label="회원">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="guardId" reference="guards" label="평가한 가드">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="content" label="리뷰 내용" />
      <DateField source="createdAt" label="리뷰 날짜" />
    </SimpleShowLayout>
  </Show>
);

export default FeedbackShow;
