import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";

const MissionsList = (props) => (
  <List {...props} title="경호 가드 배정 리스트">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="guardId" reference="guards">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="guardName" />
      <TextField source="guardPhone" />
      <ReferenceField source="customerId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="startDate" />
      <TextField source="fromAddress" />
      <TextField source="fromLat" />
      <TextField source="fromLng" />
      <TextField source="toAddress" />
      <TextField source="toLat" />
      <TextField source="toLng" />
      <TextField source="purpose" />
      <TextField source="status" />
    </Datagrid>
  </List>
);

export default MissionsList;
