import React, { cloneElement, Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  BulkDeleteButton,
  EditButton,
} from "react-admin";

const PostBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} label="삭제" />
  </Fragment>
);

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton
        basePath={basePath}
        variant="contained"
        color="primary"
        label="생성"
        style={{ fontSize: 14 }}
      />
    </TopToolbar>
  );
};

const CompanyList = (props) => {
  return (
    <List
      {...props}
      title="회사 정보"
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <Datagrid>
        {/* <TextField source="id" label="ID" /> */}
        <TextField source="name" label="회사 대표" />
        <TextField source="phone" label="회사 전화번호" />
        <TextField source="licensenumber" label="허가번호" />
        <TextField source="address" label="회사 주소" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
