import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  Create,
  useNotify,
  useRedirect,
  SaveButton,
  Toolbar,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";

export const UserCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="생성" submitOnEnter={true} icon={<AddIcon />} />
    </Toolbar>
  );
  return (
    <Create {...props} title="유저 추가" onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <TextInput source="name" label="이름" />
        <TextInput source="email" label="이메일" />
        <TextInput source="password" label="비밀번호" />
        <TextInput source="passwordConfirmation" label="비밀번호 확인" />
        <TextInput source="phone" label="전화번호" />
        <TextInput
          source="legend"
          label="회원 타입"
          placeholder="ex) guard 아니면 customer"
        />
        <BooleanInput source="isMan" label="성별이 남자라면 활성화" />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
