import React, { useState } from "react";
import {
  Show,
  TextField,
  ReferenceField,
  DateField,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  EditButton,
  useShowController,
  TopToolbar,
  DeleteButton,
  ShowButton,
  FunctionField,
  NumberField,
  List,
} from "react-admin";
import "./reservations.css";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { NavLink } from "react-router-dom";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton
      basePath={basePath}
      record={data}
      label="수정"
      variant="contained"
    />
  </TopToolbar>
);

const ShowButtons = (props) => {
  return (
    <ShowButton
      basePath={`/missions/${props.record.id}/show`}
      color="primary"
      label="상세보기"
      style={{ fontSize: 14 }}
      icon={<FindInPageRoundedIcon />}
    />
  );
};

const EditButtons = (props) => {
  return (
    <EditButton
      basePath={`/missions/${props.record.id}/edit`}
      color="primary"
      label="수정"
      style={{ fontSize: 14 }}
    />
  );
};

const ReservationsShow = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDay, setIsDay] = useState();
  const [isSelect, setIsSelect] = useState();

  const { record } = useShowController(props);

  // const [isPays, setIsPays] = useState("");

  const handleDay = (e) => {
    setIsDay(e.target.value);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const Modal = () => {
    return (
      <div className="container">
        <div className="backdrop" onClick={() => setIsOpen(false)}></div>
        <div className="box">
          <div className="title">신고 날짜를 선택해주세요.</div>
          <div>
            <Input
              type="date"
              style={{ width: "100%", margin: "25px 0px" }}
              onChange={handleDay}
              value={isDay}
            />
          </div>
          <a
            href={`guard.html?id=${isSelect}&date=${isDay}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
            >
              신고서 미리보기
            </Button>
          </a>
        </div>
      </div>
    );
  };

  const DownLoadButton = ({ record }) => {
    return (
      <Button
        color="primary"
        style={{ width: "100%" }}
        onClick={() => {
          setIsSelect(record.id);
          handleOpen();
        }}
      >
        <GetAppRoundedIcon />
        신고서
      </Button>
    );
  };

  const PayButton = ({ record }) => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          onPay(record.id);
        }}
        style={{ margin: "10px 0 " }}
      >
        <CreditCardRoundedIcon style={{ marginRight: 10 }} />
        결제하기
      </Button>
    );
  };

  const onPay = async (id) => {
    const url = `https://dev.api.ldgrd.com/reservations/${id}/pay`;
    const token = localStorage.getItem("token");
    const options = { headers: { Authorization: `Bearer ${token}` } };

    try {
      await axios.get(url, options);
      alert("결제가 완료되었습니다.");
    } catch (error) {
      alert("다시 시도해 주세요.");
    }
  };

  const userId = record?.customerId;
  const fromAddress = record?.fromAddress;
  const toAddress = record?.toAddress;
  const fromLat = record?.fromLat;
  const fromLng = record?.fromLng;
  const toLat = record?.toLat;
  const toLng = record?.toLng;
  const purpose = record?.purpose;

  return (
    <>
      <Show
        {...props}
        title="경호 예약 관리 상세"
        actions={<PostShowActions />}
      >
        <SimpleShowLayout>
          {/* <TextField source="id" /> */}
          <ReferenceField
            source="customerId"
            reference="users"
            label="회원이름"
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            source="guardingType"
            label="경호타입"
            render={(record) => {
              return (
                <div>
                  {record.guardingType === "PICKUP" ? "안심 픽업" : "신변보호"}
                </div>
              );
            }}
          />
          {/* <ReferenceField source="customerName" reference="customers">
        <TextField source="name" />
      </ReferenceField> */}
          <TextField source="phone" label="연락처" />
          <TextField source="fromAddress" label="출발지" />
          <TextField source="toAddress" label="도착지" />
          {/* <TextField source="fromLat" />
      <TextField source="fromLng" />
      <TextField source="toLat" />
      <TextField source="toLng" /> */}
          <DateField source="startDate" label="가드 시작일" showTime />
          <DateField source="endDate" label="가드 종료일" showTime />
          <TextField source="purpose" label="경호 상세 내용" />
          <FunctionField
            source="price"
            label="결제 금액"
            render={(record) => {
              return (
                <div>
                  {record.price === undefined ? (
                    ""
                  ) : (
                    <div>
                      {String(record.price).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      원
                    </div>
                  )}
                </div>
              );
            }}
          />
          <FunctionField
            source="isPaid"
            label="결제 여부"
            render={(record) => {
              return (
                <div>
                  {record.isPaid === true ? (
                    <div>결제완료</div>
                  ) : (
                    <div>미결제</div>
                  )}
                </div>
              );
            }}
          />
          <EditButton
            variant="contained"
            color="primary"
            label="수정"
            style={{ width: 115, height: 36.5, marginRight: 10 }}
          />
          <PayButton />
          {/* <DateField source="createdAt" /> */}
          {/* <TextField source="status" /> */}
          <TabbedShowLayout>
            <Tab label="가드플랜">
              <div
                style={{
                  width: 120,
                  marginLeft: "auto",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 10,
                  display: "flex",
                }}
              >
                <NavLink
                  to={{
                    pathname: "/missions/create",
                    state: {
                      id: props.id,
                      userId: userId,
                      fromAddress: fromAddress,
                      toAddress: toAddress,
                      fromLat: fromLat,
                      fromLng: fromLng,
                      toLat: toLat,
                      toLng: toLng,
                      purpose: purpose,
                    },
                  }}
                  style={{
                    textDecoration: "none",
                    color: "#2F80ED",
                  }}
                >
                  <Button variant="contained" color="primary">
                    + 가드 추가
                  </Button>
                </NavLink>
              </div>
              <ReferenceManyField
                label=""
                target="reservationId"
                reference="missions"
                filter={{ reservationId: "" }}
              >
                <List bulkActionButtons={<></>} actions={<></>}>
                  <Datagrid>
                    {/* <TextField source="id" label="ID" /> */}
                    <ReferenceField
                      source="guardId"
                      reference="guards"
                      label="보디가드"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <DateField source="startDate" label="가드 시작일" />
                    <DateField source="endDate" label="가드 종료일" />
                    <TextField source="fromAddress" label="시작지점" />
                    <TextField source="toAddress" label="종료지점" />
                    <TextField source="purpose" label="경비 목적 또는 내용" />
                    <DateField
                      source="startedAt"
                      label="가드 시작시간"
                      showTime
                    />
                    <DateField
                      source="endedAt"
                      label="가드 종료시간"
                      showTime
                    />
                    <EditButtons />
                    <DownLoadButton />
                    <ShowButtons />
                    <DeleteButton />
                  </Datagrid>
                </List>
              </ReferenceManyField>
            </Tab>
            <Tab label="결제 내역">
              <ReferenceManyField
                label=" "
                target="reservationId"
                reference="payments"
                filter={{ reservationId: "" }}
              >
                <Datagrid>
                  {/* <TextField source="id" /> */}
                  {/* <ReferenceField source="paymentId" reference="payments">
                    <TextField source="id" />
                  </ReferenceField> */}
                  {/* <ReferenceField
                    source="reservationId"
                    reference="reservations"
                  >
                    <TextField source="id" />
                  </ReferenceField> */}
                  <ReferenceField
                    source="userId"
                    reference="users"
                    label="회원 이름"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <FunctionField
                    source="price"
                    label="결제 금액"
                    render={(record) => {
                      return (
                        <div>
                          {String(record.price).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                          원
                        </div>
                      );
                    }}
                  />
                  <DateField source="createdAt" label="결제 날짜" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        </SimpleShowLayout>
      </Show>
      <>{isOpen ? <Modal /> : ""}</>
    </>
  );
};

export default ReservationsShow;
