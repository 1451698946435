import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  SaveButton,
  Toolbar,
} from "react-admin";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

const UsermemoCreate = (props) => {
  const history = useHistory();

  const id = props.location.state?.id;

  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("가드가 추가 되었습니다!");
    history.go(-1);
  };

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label="생성"
        submitOnEnter={true}
        icon={<AddIcon />}
      ></SaveButton>
    </Toolbar>
  );

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceInput
          source="userId"
          reference="users"
          label="유저"
          initialValue={id}
        >
          <SelectInput optionText="name" initialValue={id} />
        </ReferenceInput>
        <TextInput source="content" label="내용" />
        {/* <DateInput source="createdAt" /> */}
      </SimpleForm>
    </Create>
  );
};

export default UsermemoCreate;
