import React from "react";
import { TextInput, SimpleForm, Edit, SaveButton, Toolbar } from "react-admin";

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="저장" redirect="list" submitOnEnter={true} />
  </Toolbar>
);

const CompanyEdit = (props) => (
  <Edit {...props} title="회사 정보 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      {/* <TextInput source="id" label="ID" /> */}
      <TextInput source="name" label="회사 대표" />
      <TextInput source="phone" label="회사 전화번호" resettable />
      <TextInput source="licensenumber" label="허가번호" fullWidth resettable />
      <TextInput source="address" label="회사 주소" fullWidth resettable />
    </SimpleForm>
  </Edit>
);

export default CompanyEdit;
